<template>
	<div class="wrapper" style="padding-top: 56px;">
		<!-- <transition-group name="flip-list" tag="div" class="wrapper"> -->
			<b-navbar key="nav" toggleable="md" type="dark" variant="dark" fixed="top">
				<b-navbar-toggle target="nav_collapse" />
				<b-navbar-brand to="/">SkvizBiz</b-navbar-brand>
				<b-collapse is-nav id="nav_collapse">
					<b-navbar-nav>
						<b-nav-item :class="{'active': $nuxt.$route.name == 'index'}" exact to="/">Forside</b-nav-item>
						<b-nav-item :class="{'active': $nuxt.$route.name == 'hvorfor'}" exact to="/hvorfor">Hvorfor SkvizBiz?</b-nav-item>
						<b-nav-item :class="{'active': $nuxt.$route.name == 'videoer'}" exact to="/videoer">Videoer</b-nav-item>
						<b-nav-item :class="{'active': $nuxt.$route.name == 'priser'}" exact to="/priser">Priser</b-nav-item>
						<b-nav-item :class="{'active': $nuxt.$route.name == 'tilmelding'}" exact to="/tilmelding">Tilmelding</b-nav-item>
						<b-nav-item :class="{'active': $nuxt.$route.name && $nuxt.$route.name.match(/^blog/i)}" exact to="/blog">Blog</b-nav-item>
					</b-navbar-nav>
					<b-button class="ml-auto" variant="success" href="https://secure.skvizbiz.dk"><fa :icon="fasLock"></fa>&nbsp;Log&nbsp;ind</b-button>
				</b-collapse>
			</b-navbar>
			<main>
				<b-container fluid v-if="current_incident" class="bg-warning">
					<b-container class="pt-2 pb-2">
						<b-row>
							<b-col>
								<a :href="current_incident.direct_link ? current_incident.direct_link : 'https://status.skvizbiz.dk'" target="_blank" rel="noopener noreferrer" class="text-dark" style="text-decoration: none;"><span><strong>SkvizBiz status: {{current_incident.title}}</strong><br><div v-html="current_incident.description_html"></div></span><span v-html="current_incident.additional_text"></span></a>
							</b-col>
						</b-row>
					</b-container>
				</b-container>
			<!-- <div :key="$route.name"> -->
				<nuxt />
			<!-- </div> -->
			</main>
			<b-container key="footer" class="bg-dark" fluid tag="footer" style="margin-top: 30px; padding-top: 15px; padding-bottom: 15px;">
				<b-card-group>
					<b-card bg-variant="dark" border-variant="dark" header-bg-variant="dark" header-border-variant="dark" text-variant="white" style="flex: 1 0 210px;">
						<h5>Kontakt SkvizBiz</h5>
						<p>
							SkvizBiz.dk<br>
							Dyrehavegårdsvej 17<br>
							6000 Kolding<br>
							CVR: 37243221
						</p>
						<p>
							T: 77 34 09 17<br>
							E: <a href="mailto:mail@skvizbiz.dk">mail@skvizbiz.dk</a>
						</p>
						<p>
							<b-link to="/privacypolicy">Privatlivpolitik</b-link>
						</p>
					</b-card>
					<b-card bg-variant="dark" border-variant="dark" header-bg-variant="dark" header-border-variant="dark" text-variant="white" style="flex: 1 0 310px;">
						<h5>SkvizBiz support</h5>
						<p>Supporttelefonen er åben kl. 14-17,<br>
						mandag, tirsdag og torsdag,<br>
						samt 13-16 fredag</p>
						<p>T: 77 34 09 17<br>
						E: <a href="mailto:support@skvizbiz.dk">support@skvizbiz.dk</a></p>
						<b-button href="https://get.teamviewer.com/skvizbiz">Start onlinesupport</b-button>
					</b-card>
					<b-card bg-variant="dark" border-variant="dark" header-bg-variant="dark" header-border-variant="dark" text-variant="white" style="flex: 100 1 380px" class="d-none d-lg-flex">
						<h5>Let planlægning, kundestyring og administration</h5>
						<p>SkvizBiz er programmet til vinduespudsere. Det er en online løsning, der samler kundestyring, planlægning, 
						regnskab og anden administration af din virksomhed 
						ét sted. SkvizBiz er udviklet i samarbejde med en lang 
						række virksomheder, der tilbyder vinduespolering, 
						rengøring og lignende serviceydelser, og programmet 
						er på den måde skræddersyet til branchen.</p>
					</b-card>
				</b-card-group>
			</b-container>
			<!-- <vue-futurelink></vue-futurelink> -->
		<!-- </transition-group> -->
	</div>
</template>

<!--<script>
// import {} from "../assets/fontawesome-pro-5.4.2-web/js/all.js";
// export default {};
</script>-->

<script>
import { fasLock } from "!!fa-icon-loader!~/assets/@fortawesome/fontawesome-pro/metadata/icons.json";
// import vueFuturelink from "vue-futurelink";

import {
	BNavbar,
	BNavbarToggle,
	BNavbarBrand,
	BCollapse,
	BNavbarNav,
	BNavItem,
	BButton,
	BContainer,
	BRow,
	BCol,
	BCardGroup,
	BCard,
	BLink,
} from "bootstrap-vue";

export default {
	head() {
		let title = this.$route.name;
		if (title) {
			if (title === "index") {
				title = "velkommen";
			}
			title = title.charAt(0).toUpperCase() + title.slice(1);
			return {
				titleTemplate: `%s - ${title}`,
			};
		}
	},
	components: {
		// vueFuturelink,
		BNavbar,
		BNavbarToggle,
		BNavbarBrand,
		BCollapse,
		BNavbarNav,
		BNavItem,
		BButton,
		BContainer,
		BRow,
		BCol,
		BCardGroup,
		BCard,
		BLink,
	},
	data() {
		return {
			incidents: [],
		};
	},
	mounted() {
		this.update_status();
		setInterval(()=>{this.update_status()}, 30000);
	},
	methods: {
		async update_status() {
			try {
				let axios = await import("axios");
				let { Autolinker } = await import('autolinker');
				
				let start_time = new Date();
				start_time.setMonth(start_time.getMonth()-1);
				let m = `0${start_time.getMonth()+1}`.substr(-2);
				let d = `0${start_time.getDate()}`.substr(-2);
				start_time = `${start_time.getFullYear()}-${m}-${d}T00:00:00Z`
				let incidents = await axios.get(`https://api.freshstatus.io/v1/public-incidents/?account_id=6069&start_date__gte=${start_time}`).then(response => response.data.results);

				incidents = Object.values(incidents)
				let compare_date = new Date();
				let compare_date_planned = new Date();
				compare_date_planned.setDate(compare_date_planned.getDate()+7);
				incidents = incidents.filter(incident => {
					let cmp;
					if (incident.is_planned) {
						cmp = compare_date_planned;
					} else {
						cmp = compare_date;
					}
					return new Date(incident.start_time) < cmp && (!incident.end_time || new Date(incident.end_time) > compare_date);
				})
				let length_limit = 100
				incidents.map(incident => {
					let description = incident.description;
					incident.additional_text = "<br>Klik her for at følge status."
					if (description.length > length_limit) {
						description = description.substr(0,length_limit-40) + "... (Klik for at læse hele beskrivelsen og se eventuelle opdateringer)";
						incident.additional_text = "";
					}
					incident.direct_link = `https://status.skvizbiz.dk/incident/${incident.id}`;
					incident.description_html = Autolinker.link(description, {stripPrefix: false}).replace(/\n/g, "<br>");
					return incident;
				});
				this.incidents = incidents;
			} catch (err) {
				console.log(err);
			}
		}
	},
	computed: {
		fasLock() {return fasLock},
		current_incident() {
			return this.incidents.length > 0 ? this.incidents[0] : false;
		}
	},
	transition: {
		name: "page",
		// mode: "out-in",
		// mode: "in-out",
		mode: "default",
	},
};
</script>


<style lang="scss">
@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'node_modules/bootstrap-vue/src/index.scss';

/* $theme-colors: (
	"primary": #4fc3f7,
);

@import "~bootstrap/scss/bootstrap.scss";

*/

// .flip-list-move {
// 	transition: transform 0.3s;
// }

.page-enter-active,
.page-leave-active {
	transition: opacity .15s ease-out;
	// transition: all 0.15s ease-out;
}
// body .page-leave-to {
// 	overflow: visible;
// 	height: 0 !important;
// 	// margin-top: 0 !important;
// 	margin-bottom: 0 !important;
// 	// padding-top: 0 !important;
// 	padding-bottom: 0 !important;
// 	// float: left;
// 	// position: relative;
// }
body .page-leave-to {
	position: absolute;
	transform: translateX(-50%);
	margin-left: 50%;
}
.page-enter,
.page-leave-to {
	opacity: 0.01;
}
// .page-enter {
// 	transform: translateX(25%);
// }
// .page-leave-to {
// 	transform: translateX(-25%);
// }

html {
	font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont,
		"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	font-size: 16px;
	word-spacing: 1px;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: border-box;
	margin: 0;
}

body {
	background-color: #f2fbff;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23bbe9fd' fill-opacity='0.3' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}

main a {
	color: #005bbd;
	font-weight: 500;
}


.wrapper {
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	> * {
		flex: 1 1 auto;
	}
	> nav,
	> footer {
		flex: 0 0 auto;
	}
}

.emoji {
	font-family: Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji,Emoji,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif;
	font-size: 1.2em;
	line-height: 1em;
}

.clickable {
	cursor: pointer;
}
</style>
